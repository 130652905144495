<script setup>
import { provide } from 'vue'

const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
})

provide('index', props.index)
</script>

<template>
  <div class="accordion-item flex flex-col items-center justify-center">
    <slot />
  </div>
</template>
