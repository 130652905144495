<script setup>
import { computed, inject } from 'vue'

const props = defineProps({
  showPlusMinus: {
    type: Boolean,
    default: false,
  },
  alignLeft: {
    type: Boolean,
    default: true,
  },
})
const activeItem = inject('activeItem')
const index = inject('index')

const isActive = computed(() => activeItem.value === index)

function toggle() {
  const newValue = activeItem.value === index ? undefined : index
  if (activeItem.value === undefined || newValue === undefined) {
    activeItem.value = newValue
  }
  else {
    activeItem.value = undefined
    setTimeout(() => {
      activeItem.value = newValue
    }, 350)
  }
}
</script>

<template>
  <div
    class="accordion-header"
    :class="{
      'active': isActive,
      'flex items-center': showPlusMinus,
      'justify-center': !alignLeft,
      'justify-start': alignLeft,
    }"
    @click="toggle"
  >
    <div v-if="showPlusMinus" class="flex">
      <svg
        v-if="!isActive"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="currentColor"
        viewBox="0 0 256 256"
        class="w-4 h-4 mr-1 md:w-5 md:h-5 md:mr-2"
      >
        <path
          d="M228,128a12,12,0,0,1-12,12H140v76a12,12,0,0,1-24,0V140H40a12,12,0,0,1,0-24h76V40a12,12,0,0,1,24,0v76h76A12,12,0,0,1,228,128Z"
        />
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="currentColor"
        viewBox="0 0 256 256"
        class="w-4 h-4 mr-1 md:w-5 md:h-5 md:mr-2"
      >
        <path d="M228,128a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128Z" />
      </svg>
    </div>
    <slot />
  </div>
</template>

<style scoped>
.accordion-header {
  @apply text-center cursor-pointer font-extralight text-lg text-white hover:underline md:text-xl uppercase underline-offset-4;

  &.active {
    @apply underline;
  }
}
</style>
